/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { InboxOutlined } from "@mui/icons-material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";

import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import Dragger from "antd/es/upload/Dragger";
import { Button, Upload, message } from "antd";

const props: UploadProps = {
  name: "file",
  multiple: false,
  maxCount: 1,
  beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
    const isMp4 = file.type === "video/mp4";

    if (!isJpgOrPng && !isMp4) {
      message.error("You can only upload JPG/PNG/JPEG image and MP4 video files!");
      return Upload.LIST_IGNORE; // 이 파일을 업로드 목록에서 제외합니다.
    }

    return true; // 파일 형식이 조건에 맞으면 업로드를 계속 진행합니다.
  },
  customRequest({ file, onSuccess }) {
    const res = "Ok";
    onSuccess!(res);
  },
  // onChange(info) {
  //   const { status } = info.file;
  //   if (status !== "uploading") {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (status === "done") {
  //     message.success(`${info.file.name} file uploaded successfully.`);
  //   } else if (status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  // },
  // onDrop(e) {
  //   console.log("Dropped files", e.dataTransfer.files);
  // },
};

interface Props {
  media: string;
  type: string;
  enabled: number;
  putMediaBanner: Function;
}

function MediaInfo({ media, type, enabled, putMediaBanner }: Props): JSX.Element {
  const [data, setData] = useState({ media, type, enabled });
  const [file, setFile] = useState<UploadFile[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const handleClickEditButton = () => {
    setOpen((prev) => !prev);
  };

  const handleClickUpdateMedia = () => {
    const mimeType = file[0].type;
    putMediaBanner(mimeType, file).finally(() => {
      setOpen(false);
    });
  };

  const onChangeMusicFile: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setFile(newupdatedFileList);
  };

  useEffect(() => {
    setData({
      media,
      type,
      enabled,
    });
  }, [media, type, enabled]);
  return (
    <Card
    // sx={{
    //   "&:hover .card-header": {
    //     transform: "translate3d(0, -50px, 0)",
    //   },
    // }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        // sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        {/* 야야 type = video, image, short, live, ssp임. 이제부터 분리해서 표시해라, ssp일때는 미디어에 해당 ssp 이름 있으니까  그걸로 표시해 */}
        {/* {data?.type === "video" || data?.type === "shorts" ? (
          <MDBox
            component="video"
            muted
            autoPlay
            loop
            src={`${process.env.REACT_APP_IMG_ENDPOINT}` + data?.media}
            alt="Banner ThumbNail"
            borderRadius="lg"
            shadow="sm"
            width={data.type === "shorts" ? "50%" : "100%"}
            // height="100%"
            position="relative"
            zIndex={10}
            mb={2}
          />
        ) : (
          <MDBox
            component="img"
            src={`${process.env.REACT_APP_IMG_ENDPOINT}` + data?.media}
            alt="Banner ThumbNail"
            borderRadius="lg"
            shadow="sm"
            width="100%"
            height="100%"
            position="relative"
            zIndex={10}
            mb={2}
          />
        )} */}
        {data?.type === "video" || data?.type === "shorts" ? (
          <MDBox
            component="video"
            muted
            autoPlay
            loop
            src={`${process.env.REACT_APP_IMG_ENDPOINT}` + data?.media}
            alt="Banner ThumbNail"
            borderRadius="lg"
            shadow="sm"
            width={data.type === "shorts" ? "50%" : "100%"}
            position="relative"
            zIndex={10}
            mb={2}
          />
        ) : data?.type === "live" ? (
          <MDBox
            width={160}
            height={256}
            display="flex"
            bgColor="white"
            alignItems="center"
            justifyContent="center"
            style={{ border: "1px solid #ccc", borderRadius: "4px" }}
          >
            {media}
          </MDBox>
        ) : (
          <MDBox
            component="img"
            src={`${process.env.REACT_APP_IMG_ENDPOINT}` + data?.media}
            alt="Banner ThumbNail"
            borderRadius="lg"
            shadow="sm"
            width="100%"
            height="100%"
            position="relative"
            zIndex={10}
            mb={2}
          />
        )}
      </MDBox>
      <MDBox textAlign="center" pt={0} pb={3} px={3}>
        <MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <Typography variant="h6" fontWeight="light">
              타입
            </Typography>
            <Typography variant="h6" fontWeight="regular">
              {data?.type}
            </Typography>
          </MDBox>

          <Typography variant="h6" fontWeight="regular" textAlign="right">
            <MDBadge
              variant="contained"
              size="xs"
              badgeContent={data?.enabled ? "광고 송출" : "광고 중지"}
              color={data?.enabled ? "success" : "warning"}
              container
            />
          </Typography>
          <MDBox mt={1}>
            <Typography variant="h6" fontWeight="regular" textAlign="right">
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  onClick={handleClickEditButton}
                >
                  수정하기
                </MDButton>
              </MDBox>
            </Typography>
          </MDBox>
        </MDBox>
      </MDBox>
      {open && (
        <MDBox p={3}>
          <Divider />
          <MDBox>
            <Dragger
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              accept="image/png,image/jpeg,image/jpg,video/mp4"
              fileList={file}
              onChange={onChangeMusicFile}
              {...props}
            >
              {file.length === 0 ? (
                <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company
                    data or other banned files.
                  </p>
                </>
              ) : (
                <Button block disabled>
                  only one Banner pasible
                </Button>
              )}
            </Dragger>
          </MDBox>
          <MDBox ml="auto" mt={1}>
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              fullWidth
              disabled={file.length === 0}
              onClick={() => handleClickUpdateMedia()}
            >
              update media banner
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </Card>
  );
}

export default MediaInfo;
