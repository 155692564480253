import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDProgress from "components/MDProgress";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ant design
import { App, Upload, message } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import Dragger from "antd/es/upload/Dragger";

// NewProduct page components
import FormField from "layouts/pages/banners/new-banner/components/FormField";
import { InboxOutlined } from "@mui/icons-material";
import Apis from "apis/remotes";
import axios from "axios";
import MDDatePicker from "components/MDDatePicker";
import { convertDateToYYYYMMDD } from "utils/utilDate";
const props: UploadProps = {
  name: "file",
  multiple: false,
  maxCount: 1,
  beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
    const isMp4 = file.type === "video/mp4";

    if (!isJpgOrPng && !isMp4) {
      message.error("You can only upload JPG/PNG/JPEG image and MP4 video files!");
      return Upload.LIST_IGNORE; // 이 파일을 업로드 목록에서 제외합니다.
    }

    return true; // 파일 형식이 조건에 맞으면 업로드를 계속 진행합니다.
  },
  customRequest({ file, onSuccess }) {
    const res = "Ok";
    onSuccess!(res);
  },
};

function NewBanner(): JSX.Element {
  const { message, notification } = App.useApp();
  const [file, setFile] = useState<UploadFile[]>([]);
  const [visibleTo, setVisibleTo] = useState<Date | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDateChange = (newDate: Date) => {
    setVisibleTo(new Date(newDate));
  };
  const onChangeBannerFile: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setFile(newupdatedFileList);
  };

  const handleCreateBanner = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const mimeType = file[0].type;
      const type = mimeType.split("/")[0];
      const formData = new FormData(event.currentTarget);
      const { target, client_id, duration, weight } = Object.fromEntries(formData.entries());

      const uploadResponse = await Apis.postFilesUpload(type, mimeType);
      const { path, url } = uploadResponse.data;

      await axios.put(url, file[0].originFileObj as RcFile, {
        headers: {
          "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          //   console.log(`Upload progress: ${percentCompleted}%`);
          setProgress(percentCompleted);
          // 여기에서 UI 업데이트 로직을 추가하여 사용자에게 진행 상태를 보여줄 수 있습니다.
        },
      });

      const bannerResponse = await Apis.postBanner({
        type,
        image: path.replace("cashdd", ""),
        target,
        client_id,
        duration,
        weight,
        visibleTo: visibleTo ? convertDateToYYYYMMDD(visibleTo) : null,
      });
      message.success("Success!");
    } catch (error: any) {
      notification.error({
        message: error.response?.data.message ?? "",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <Card>
                <MDBox
                  mt={-3}
                  mb={3}
                  mx={2}
                  textAlign="center"
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                >
                  <MDBox mb={1}>
                    <MDTypography variant="h3" fontWeight="bold">
                      가로 배너 추가
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="regular" color="white">
                    320x100 사이즈 캐시둥둥 광고로 띄울 배너를 추가합니다.
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <MDBox>
                    <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                      <form onSubmit={handleCreateBanner}>
                        <MDTypography variant="h5">배너 정보</MDTypography>
                        <MDBox mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField type="url" label="URL(target)" name="target" required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type="number"
                                label="클라이언트 아이디"
                                name="client_id"
                                required
                                min="0"
                                defaultValue={0}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type="number"
                                label="가중치(weight)"
                                name="weight"
                                required
                                defaultValue={1}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type="number"
                                label="지속시간(duration)"
                                name="duration"
                                required
                                defaultValue={30}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mt={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <MDTypography
                                  component="label"
                                  variant="button"
                                  fontWeight="regular"
                                  color="text"
                                >
                                  컨텐츠&nbsp;&nbsp;
                                  <MDTypography variant="caption" color="text">
                                    (required)
                                  </MDTypography>
                                </MDTypography>
                              </MDBox>

                              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <Dragger
                                  name="artwork"
                                  multiple={false}
                                  maxCount={1}
                                  listType="picture"
                                  className="upload-list-inline"
                                  showUploadList={{ showPreviewIcon: true }}
                                  accept="image/png,image/jpeg,image/jpg,video/mp4"
                                  fileList={file}
                                  onChange={onChangeBannerFile}
                                  {...props}
                                >
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">
                                    업로드 파일 선택(이미지, 동영상)
                                  </p>
                                  <p className="ant-upload-hint">
                                    파일을 이 영역으로 드래그 앤 드롭하거나 클릭하여 파일을
                                    업로드하세요.
                                  </p>
                                </Dragger>
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MDBox mb={3}>
                                <MDBox mb={2} display="inline-block">
                                  <MDTypography
                                    component="label"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    유효기한(VisibleTo)
                                  </MDTypography>
                                </MDBox>
                                <MDBox>
                                  <MDDatePicker value={visibleTo} onChange={handleDateChange} />
                                </MDBox>
                              </MDBox>
                              <MDBox mt={3} width="100%" display="flex" justifyContent="end">
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  type="submit"
                                  disabled={loading || file.length == 0}
                                >
                                  추가하기
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                          <MDProgress value={progress} />
                        </MDBox>
                      </form>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default NewBanner;
