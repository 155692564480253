import { useCallback, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ant design
import { App, Button, DatePicker, Form, Input, Upload } from "antd";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

import Cropper from "react-easy-crop";
import Apis from "apis/remotes";
import axios from "axios";
import dayjs from "dayjs";

const ffmpeg = new FFmpeg();
function NewBannerShorts(): JSX.Element {
  const { message, notification } = App.useApp();
  const [fileType, setFileType] = useState<string | null>(null);
  const [mediaSrc, setMediaSrc] = useState<string | null>(null);
  // const [croppedMediaSrc, setCroppedMediaSrc] = useState<string | null>(null);

  // const [cropFile, setCropFile] = useState<File | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const [visibleTo, setVisibleTo] = useState<Date | null>(null);
  const [visibleFrom, setVisibleFrom] = useState<Date | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleDateChangeVisibleFrom = (newDate: Date) => {
    setVisibleFrom(newDate);
  };

  const handleDateChangeVisibleTo = (newDate: Date) => {
    setVisibleTo(newDate);
  };

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = (file: File) => {
    const url = URL.createObjectURL(file);
    setMediaSrc(url);
    console.log("뭔타입이냐?", file.type.split("/")[0]);
    setFileType(file.type.split("/")[0]); // 'video' or 'image'
    return false;
  };

  const handleCrop = async (formData: any) => {
    setIsLoading(true);

    if (!mediaSrc || !croppedAreaPixels) return;

    if (fileType === "video") {
      const file = await fetch(mediaSrc).then((r) => r.blob());
      const fileName = mediaSrc.split("/").pop();
      const fileExtension = fileName!.split(".").pop();
      console.log("fileName::", fileName);
      console.log("fileExtension::", fileExtension);
      const inputFileName = `input.${fileExtension}`;
      console.log("inputFileName::", inputFileName);
      if (!ffmpeg.loaded) {
        await ffmpeg.load();
      }

      ffmpeg.writeFile(inputFileName, await fetchFile(file));
      const { x, y, width, height } = croppedAreaPixels;

      ffmpeg.on("progress", function (ratio) {
        setProgress(ratio.progress * 100);
      });

      await ffmpeg.exec([
        "-i",
        inputFileName,
        "-vf",
        `crop=${width}:${height}:${x}:${y},scale=200:320`,
        "-c:v",
        "libx264", // H.264 비디오 코덱
        "-preset",
        "ultrafast", // 더 빠른 인코딩 속도 우선
        "-crf",
        "32", // 파일 크기와 속도 균형 (값이 높을수록 속도 빠름, 품질 저하)
        "-b:v",
        "500k", // 비디오 비트레이트
        "-maxrate",
        "500k", // 최대 비트레이트
        "-bufsize",
        "1000k", // 버퍼 크기 (네트워크 전송 안정성 향상)
        "-r",
        "20", // 프레임 레이트 (20fps로 줄여서 처리 속도 향상)
        "-threads",
        "0", // 사용 가능한 모든 CPU 코어를 사용
        "-an", // 오디오 스트림 제거
        "-t",
        "32", // 영상 길이를 32초로 제한
        "-movflags",
        "faststart", // 빠른 시작 플래그 설정 (웹용 최적화)
        "output.mp4", // 출력 파일
      ]);

      const data = await ffmpeg.readFile("output.mp4");
      console.log("여기요?", data);

      const url = URL.createObjectURL(new Blob([data], { type: "video/mp4" }));

      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then(async (blob) => {
            const fileExtension = "mp4";
            const file = new File([blob], `cropped_media.${fileExtension}`, {
              type: blob.type,
            });
            // setCropFile(file);

            console.log("handleSave");

            const mimeType = file!.type;

            const type = mimeType.split("/")[0];
            // Request a pre-signed upload URL
            const { data: uploadResponse } = await Apis.postFilesUpload(type, mimeType);
            const { path, url } = uploadResponse;

            await axios.put(url, file, {
              headers: {
                "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
              },
            });

            await Apis.postBanner({
              type: "shorts",
              image: path.replace("fltt", ""),
              ...formData,
            });
            message.success("Success!");
          });
      }

      // setCroppedMediaSrc(url);
    } else if (fileType === "image") {
      // 이미지 -> 동영상 처리 (shorts일 때만)
      try {
        const file = await fetch(mediaSrc).then((r) => r.blob());
        const fileName = mediaSrc.split("/").pop();
        const fileExtension = fileName!.split(".").pop();
        console.log("fileExtension", fileExtension);
        const inputFileName = `input.${fileExtension}`;
        if (!ffmpeg.loaded) {
          await ffmpeg.load();
        }

        ffmpeg.writeFile(inputFileName, await fetchFile(file)); // 이미지 파일 입력
        const { x, y, width, height } = croppedAreaPixels;

        ffmpeg.on("progress", function (ratio) {
          setProgress(ratio.progress * 100);
        });

        const ffmpegArgs = [
          "-i",
          inputFileName, // 입력 이미지
          "-vf",
          `crop=${width}:${height}:${x}:${y},scale=200:320`, // 이미지 크롭 및 스케일
          "-c:v",
          "libx264", // H.264 비디오 코덱
          "-t",
          "30", // 동영상 길이를 30초로 설정 (필요에 따라 변경 가능)
          "-pix_fmt",
          "yuv420p", // 호환성을 위한 픽셀 포맷 설정
          "-r",
          "25", // 프레임 레이트 설정
          "-movflags",
          "faststart", // 빠른 시작 플래그 설정
          "output.mp4", // 출력 비디오 파일
        ];
        // GIF 파일을 처리할 때 추가적인 옵션을 적용
        if (fileExtension!.toLowerCase() === "gif") {
          ffmpegArgs.unshift("-ignore_loop", "0"); // 모든 프레임을 사용하도록 설정
        }

        await ffmpeg.exec(ffmpegArgs);

        const data = await ffmpeg.readFile("output.mp4");
        const url = URL.createObjectURL(new Blob([data], { type: "video/mp4" }));

        if (url) {
          fetch(url)
            .then((response) => response.blob())
            .then(async (blob) => {
              const fileExtension = "mp4";
              const file = new File([blob], `cropped_media.${fileExtension}`, {
                type: blob.type,
              });
              // setCropFile(file);

              console.log("handleSave");

              const mimeType = file!.type;

              const type = mimeType.split("/")[0];
              // Request a pre-signed upload URL
              const { data: uploadResponse } = await Apis.postFilesUpload(type, mimeType);
              const { path, url } = uploadResponse;

              await axios.put(url, file, {
                headers: {
                  "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
                },
              });

              await Apis.postBanner({
                type: "shorts",
                image: path.replace("cashdd", ""),
                ...formData,
              });
              message.success("Success!");
            });
        }

        // setCroppedMediaSrc(url);
      } catch (error) {
        console.error("Error encoding image to video:", error);
      } finally {
        setProgress(100);
      }
    }
    setIsLoading(false);
  };

  const handleCreateShortsAds = async (data: any) => {
    console.log(data);

    if (data.visibleFrom) {
      const formattedVisibleFrom = dayjs(data.visibleFrom).format("YYYY-MM-DD HH:mm:ss");
      data.visibleFrom = null;
    }

    if (data.visibleTo) {
      const formattedVisibleTo = dayjs(data.visibleTo).format("YYYY-MM-DD HH:mm:ss");
      data.visibleTo = null;
    }
    console.log("Formatted Data:", data);

    try {
      await handleCrop(data); // First, crop the media
      //await handleSave(data); // Then, save the cropped media
      // await handleUploadBanner(data); // Finally, upload the banner
    } catch (error) {
      console.error("An error occurred while processing:", error);
    }
  };

  // const handleSave = async (formData: any) => {
  //   try {
  //     if (croppedMediaSrc) {
  //       fetch(croppedMediaSrc)
  //         .then((response) => response.blob())
  //         .then(async (blob) => {
  //           const fileExtension = fileType === "video" ? "mp4" : "jpeg";
  //           const file = new File([blob], `cropped_media.${fileExtension}`, {
  //             type: blob.type,
  //           });
  //           // setCropFile(file);

  //           console.log("handleSave");

  //           const mimeType = file!.type;

  //           const type = mimeType.split("/")[0];
  //           // Request a pre-signed upload URL
  //           const { data: uploadResponse } = await Apis.postFilesUpload(type, mimeType);
  //           const { path, url } = uploadResponse;

  //           await axios.put(url, file, {
  //             headers: {
  //               "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
  //             },
  //           });

  //           await Apis.postBanner({
  //             type: "shorts",
  //             image: path.replace("fltt", ""),
  //             ...formData,
  //           });
  //           message.success("Success!");
  //         });
  //     }
  //   } catch (error) {}
  // };

  // const handleUploadBanner = async (formData: any) => {
  //   console.log("handleUploadBanner");
  //   if (!cropFile) {
  //     return;
  //   }
  //   try {
  //     const mimeType = cropFile!.type;
  //     const type = mimeType.split("/")[0];
  //     // Request a pre-signed upload URL
  //     const { data: uploadResponse } = await Apis.postFilesUpload(type, mimeType);
  //     const { path, url } = uploadResponse;

  //     await axios.put(url, cropFile, {
  //       headers: {
  //         "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
  //       },
  //     });

  //     await Apis.postBanner({
  //       type: "shorts",
  //       image: path.replace("cashdd", ""),
  //       ...formData,
  //     });
  //     message.success("Success!");
  //   } catch (error: any) {
  //     notification.error({
  //       message: error.response?.data.message ?? "",
  //     });
  //   } finally {
  //   }
  // };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <Card>
                <MDBox
                  mt={-3}
                  mb={3}
                  mx={2}
                  textAlign="center"
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                >
                  <MDBox mb={1}>
                    <MDTypography variant="h3" fontWeight="bold">
                      세로 배너 추가
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="regular" color="white">
                    160x256 사이즈 캐시둥둥 광고로 띄울 배너를 추가합니다.
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Upload.Dragger
                        beforeUpload={handleFileChange}
                        accept={"video/*, .jpeg, .png, .jpg, .gif, .tiff, .bmp"}
                        showUploadList={false}
                        style={{
                          width: "100%",
                          height: "320px",
                          background: mediaSrc ? "transparent" : "gray",
                          opacity: mediaSrc ? 1 : 0.5,
                        }}
                        openFileDialogOnClick={mediaSrc ? false : true}
                      >
                        {mediaSrc ? (
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "320px",
                            }}
                          >
                            {fileType === "video" && (
                              <video
                                ref={videoRef}
                                src={mediaSrc}
                                controls
                                style={{ display: "none" }}
                              />
                            )}
                            <Cropper
                              image={fileType === "image" ? mediaSrc : undefined}
                              video={fileType === "video" ? mediaSrc : undefined}
                              crop={crop}
                              zoom={zoom}
                              aspect={200 / 320}
                              onCropChange={setCrop}
                              onZoomChange={setZoom}
                              onCropComplete={onCropComplete}
                            />
                          </div>
                        ) : (
                          // 배너 파일 선택의 기능이 드래그 앤 드롭으로 아래 div에 주고 싶음.
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "320px",
                              background: "gray",
                              opacity: 0.5,
                              padding: "20px",
                            }}
                          >
                            파일을 여기에 드래그 앤 드롭하세요
                          </div>
                        )}
                      </Upload.Dragger>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Form layout="vertical" onFinish={handleCreateShortsAds}>
                        {/* URL input */}
                        <Form.Item
                          label="URL(target)"
                          name="target"
                          rules={[
                            { required: true, type: "url", message: "Please input a valid URL!" },
                          ]}
                        >
                          <Input type="url" placeholder="Enter target URL" />
                        </Form.Item>

                        {/* Client ID input */}
                        <Form.Item
                          label="클라이언트 아이디"
                          name="client_id"
                          rules={[
                            {
                              required: true,
                              type: "number",
                              min: 0,
                              message: "Please input a valid client ID!",
                            },
                          ]}
                          initialValue={0}
                        >
                          <Input type="number" min={0} placeholder="Enter client ID" />
                        </Form.Item>

                        {/* Weight input */}
                        <Form.Item
                          label="가중치(weight)"
                          name="weight"
                          rules={[
                            {
                              required: true,
                              type: "number",
                              message: "Please input a valid weight!",
                            },
                          ]}
                          initialValue={1}
                        >
                          <Input type="number" min={1} placeholder="Enter weight" />
                        </Form.Item>

                        {/* Duration input */}
                        <Form.Item
                          label="지속시간(duration)"
                          name="duration"
                          rules={[
                            {
                              required: true,
                              type: "number",
                              message: "Please input a valid duration!",
                            },
                          ]}
                          initialValue={30}
                        >
                          <Input type="number" placeholder="Enter duration in seconds" />
                        </Form.Item>

                        {/* DatePicker with time (visible from) */}
                        <Form.Item
                          label="시작 시간(visible from)"
                          name="visibleFrom"
                          rules={[
                            { required: true, message: "Please select a start date and time!" },
                          ]}
                        >
                          <DatePicker
                            value={visibleFrom}
                            onChange={handleDateChangeVisibleFrom}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                          />
                        </Form.Item>

                        {/* DatePicker with time (visible to) */}
                        <Form.Item
                          label="종료 시간(visible to)"
                          name="visibleTo"
                          rules={[
                            { required: true, message: "Please select an end date and time!" },
                          ]}
                        >
                          <DatePicker
                            value={visibleTo}
                            onChange={handleDateChangeVisibleTo}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                          />
                        </Form.Item>

                        {/* Submit button */}
                        <Form.Item>
                          <Button
                            disabled={mediaSrc === null}
                            // onClick={handleCrop}
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            block
                            className="rounded-full"
                            size="large"
                          >
                            세로 배너 생성
                          </Button>
                        </Form.Item>
                      </Form>
                    </Grid>
                  </Grid>
                  <MDProgress value={progress} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default NewBannerShorts;
