/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import Apis from "apis/remotes";

import { Table } from "antd";
// import BannerCell from "./components/BannerCell";
// import DataTable from "./components/BannerTable";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetClientBanner } from "apis/response";

function RequestBannerList(): JSX.Element {
  const navigate = useNavigate();
  const [data, setData] = useState<IGetClientBanner[]>([]);

  const columns: ColumnsType<IGetClientBanner> = [
    {
      title: "배너",
      dataIndex: "media",
      key: "media",
      ellipsis: true,
      // type이 이미지이면 이미지 , 동영상이면 동영상인데 소리없이 컨트롤러 안보이고 무한루프, 이미지 동영상 모두 가로320 세로 100사이즈.
      render: (media: string, record: IGetClientBanner) => {
        return record.type === "image" ? (
          <img src={process.env.REACT_APP_IMG_ENDPOINT + media} alt="banner" width={160} />
        ) : (
          <video
            src={process.env.REACT_APP_IMG_ENDPOINT + media}
            width={160}
            autoPlay
            loop
            muted
            controls={false}
          />
        );
      },
    },
    {
      title: "배너 타입",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
    },
    {
      title: "등록요청일",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    Apis.getClientBanners().then((resolve) => {
      setData(resolve.data.list);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <Card>
          <Table
            dataSource={data}
            columns={columns}
            rowKey={(record) => record.id}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/page/banners/detail-banner/${record.id}`);
                },
              };
            }}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RequestBannerList;
